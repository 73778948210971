var pathname = function () {
  return location.pathname == "/index.html" ? "/" : location.pathname;
};

// var pathname = pathname().split('/');

$(".l-header-item").each(function () {
  var $href = $(this)
    .find("a")
    .attr("href");
  // console.log($href);
  if (location.href.match($href) && $href != "/") {
    $(this).find("a").addClass("is-current");
  }
  if ($href.split("/")[1] === location.pathname.split("/")[1]) {
    $(this).find("a").addClass("is-current");
  }
  // if (pathname() == "/") {
  //   $(".l-header-nav__text")
  //     .eq(0)
  //     .addClass("is-current");
  // }
});
